<template>
  <div>
    <BillssMonitoring :titlee="'Office Rental'">

    </BillssMonitoring>
  </div>
</template>
<script>
  import BillssMonitoring from './BillssMonitoring'

  export default {
    components: {
      BillssMonitoring,
    },
  }
</script>
